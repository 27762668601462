/* SEARCH RESULTS

  These styles make the default Google Custom Search results, which
  are included in the page via javascript, look a bit more similar to
  the rest of the website.

*/


/* ID selector for increased specificity,
   to override Google's in-built styles */
main#main-content {
  /* Main overall container */
  .gsc-control-cse {
    padding: 0;
  }

  /* All elements within the search results */
  .gsc-control-cse * {
    font-family: "nta", Arial, sans-serif;
  }

  /* Container for the bit above the results */
  .gsc-above-wrapper-area {
    border-bottom-width: 1px;
  }

  /* Spelling suggestion / autocorrection */
  .gs-spelling {
    padding-left: 0;
    color: black;
  }

  /* Spelling links */
  .gs-spelling i {
    font-style: normal;
  }

  /* Container for 'About 8 results' sentence */
  .gsc-result-info {
    display: none;
  }

  /* The 'Sort by:' label */
  .gsc-orderby-label {
    color: black;
    font-size: 16px;
  }

  /* Results header */
  .gsc-resultsHeader {
    display: none;
  }

  /* Order by dropdown */
  .gsc-orderby-container {
    display: none;
  }

  /* Table containing each result snippet */
  .gsc-table-result {
    padding-left: 0;
  }

  /* Table cell containing each result snippet */
  .gsc-table-cell-snippet-close {
    border-width: 0;
    padding-top: 0;
  }

  /* Container for result title */
  .gsc-thumbnail-inside {
    padding-left: 0;
  }

  /* Title of each search result */
  .gs-title {
    @include govuk-font(24, $weight: "bold");
    height: auto;
    max-width: 590px;
    line-height: 30px;
    text-decoration: none;
    color: govuk-colour("blue");
  }

  /* Words highlighted within title */
  .gs-title b {
    @include govuk-font(24, $weight: "bold");
    height: auto;
    max-width: 590px;
    line-height: 30px;
    text-decoration: none;
    color: govuk-colour("blue");
  }

  /* URL at the top of each search result */
  .gsc-url-top {
    display: none;
  }

  /* 'Snippet' of matching content from the page */
  .gs-snippet {
    @include govuk-font(16);
    max-width: 590px;

    & b {
      @include govuk-font(16, $weight: "bold");
    }
  }

  /* Pagination container */
  .gsc-cursor-box {
    margin-left: 0;
  }

  /* Pagination link */
  .gsc-cursor-page {
    font-size: 16px;
  }

  .gcsc-more-maybe-branding-root {
    visibility: none;
  }
}
