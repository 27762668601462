/* DS - additions for index page */
.section-list {
	padding-left: 0;
	border-bottom: 1px solid #bfc1c3;
}
.section-list li {
	list-style: none;
    cursor: pointer;
    border-top: 1px solid #bfc1c3;
    margin-bottom: 0;
}
.section-list li a {
	text-decoration: none;
  display: block;
  padding: 20px 0px 20px 10px;
}
.section-list li a:hover {
	background-color: #f3f2f1;
}
.section-list li a h2.subsection-title-text {
	text-decoration: none;
  display: block;
  padding: 0px;
  margin: 0;
  color: #005ea5;
}
.section-list li a .subsection-summary {
    color: #0b0c0c;
    display: block;
}
