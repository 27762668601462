.status {
  background-color: govuk-colour("white");
  box-sizing: border-box;
  border: 4px solid $govuk-border-colour;
  padding: 8px;
}

.status-inner {
  width: 100%;
  display: table;
}

.status .left {
  display: table-cell;
}

.status .right {
  display: table-cell;
  text-align: right;
}

.status .info {
  margin-right: 20px;
}

.status b {
  font-weight: bold;
}

.status-inner .govuk-button {
  vertical-align: baseline;
  margin-bottom: 0px;
}

.status .actions {

  .other-versions {
    display: table-cell;
    width: 50%;
    text-align: right;
    font-size: 16px;

    a {
      font-size: 16px;
      color: $govuk-link-colour;
      padding: 0;
      margin-left: 10px;
    }
  }
}

.status-banner.sticky-js {
  padding-top: 10px;
}

// TODO: figure out how to make this responsive
.status-banner.sticky-js-fixed {
  max-width: 960px;
  width: 960px;
  top: 0px;
  position: fixed;
  z-index: 2;
}
