/* Table of Contents */

.eff-table-of-contents {
  padding-left: 0;
  margin-top: 0;
  padding-bottom: 60px;
  margin-bottom: 0px;
  border-bottom: 2px solid #b1b4b6;
  @include govuk-typography-responsive(16);
}

.eff-table-of-contents > li {
  display: table-row;
}

.eff-table-of-contents > li a {
  text-decoration: none;
}

.eff-table-of-contents__number {
  display: table-cell;
  width: 24px;
}

.eff-table-of-contents__content {
  display: table-cell;
  @include govuk-responsive-padding(1, "bottom");
}
