.old-edition-notice {
  margin-top: 20px;
  border-width: 5px;
  border-style: solid;
  border-color: govuk-colour("blue");
  margin-bottom: 45px;
  padding: 20px;
}

.old-edition-notice h2 {
  margin-bottom: 10px;
}

.old-edition-notice p {
  margin-bottom: 0;
}
