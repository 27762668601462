ul.reorderable li {
  text-align: left;
  cursor: move;
}

ul.reorderable li.drop-destination-below {
  border-bottom: 4px solid grey;
}

ul.reorderable li.drop-destination-above {
  border-top: 4px solid grey;
}

ul.reorderable li::before {
  content: '⠿';
  font-size: 26px;
  line-height: 26px;
  color: lightgrey;
  display: inline-block;
  vertical-align: middle;
}

ul.reorderable li.being-dragged {
  opacity: 0.7;
}

.reordering-save-status {
  font-size: 16px;
  transition: opacity 0s linear;
  transition-delay: 0s;
}


.reordering-save-status.status-invisible {
  opacity: 0;
  transition-duration: 1s;
  transition-delay: 1s;
}
