/* Missing data explanation
 *
 * TODO: convert to BEM naming conventions.
*/
.missing-data-explanation,
.missing-data-explanation {
  @include govuk-font(16);
  color: govuk-colour("dark-grey");
}

.missing-data-explanation .explanation {
  display: inline-block;
  margin-right: 10px;
}

/* Used for showing N/A in tables */
.not-applicable {
  color: govuk-colour("dark-grey");
}

/* Asterisk – positioned as inline-block with negative margin
   so as to avoid increasing the row height. */
.not-applicable sup {
  display: inline-block;
  margin-top: -6px;
}

/* These missing data icons are typically used in table cells. */
.missing-data::after {
  background-color: #A2A4A6;
  color: white;
  display: inline-block;
  text-align: center;
  font-weight: bold;
  line-height: 22px;
  font-size: 18px;
  width: 20px;
  height: 20px;
  border-radius: 10px;
}

.missing-data.confidential::after {
  content: '!'
}

.missing-data.sample-too-small::after {
  content: '?'
}

.missing-data.not-collected::after {
  content: '–'
}
