/* Sort button modifiers
 *
 * These are used for indicating that a button can be used to re-order
 * a table by a particular column, in either ascending or descending order.
 *
 * The modifier classes should be added alongside the base component class
 * from the GOV.UK Design System of `govuk-button`
 *
 * When un-sorted (`eff-button--sort`), the button includes both up and down
 * arrows on the right, as an affordance to indicate what the button does.
 *
 * When sorted in a particular direction (`eff-button--sort-ascending` or `eff-button--sort-descending`(, a single (larger) up or down arrow is shown.
 *
 * This modifier can also be used alongside the `eff-button-link` modifier, in
 * order to style the button to look more like a link than a button.
 */
.eff-button--sort,
.eff-button--sort-ascending,
.eff-button--sort-descending {
  position: relative;
  padding: 0 10px 0 0;
}

.eff-button--sort:before,
.eff-button--sort:active:before {
  content: " \25bc"; /* ▼ */
  position: absolute;
  right: -2px;
  top: 9px;
  font-size: 0.5em;
  text-align: right;
}

.eff-button--sort:after,
.eff-button--sort:active:after {
  content: " \25b2"; /* ▲ */
  position: absolute;
  right: -2px;
  top: 0px;
  font-size: 0.5em;
  text-align: right;
}

.eff-button--sort-ascending:before,
.eff-button--sort-descending:before {
  content: none;
}

.eff-button--sort-ascending:after {
  content: " \25b2"; /* ▲ */
  font-size: .7em;
  position: absolute;
  right: -2px;
  top: 2px;
}

.eff-button--sort-descending:after {
  content: " \25bc"; /* ▼ */
  font-size: .7em;
  position: absolute;
  right: -2px;
  top: 2px;
}
