// Styles for the download data box on a data-only measure page.

.metadata {
  @include govuk-font(16);
  // padding-bottom: 40px;
  dl {
    line-height: 1.75em;
  }
  dt {
    float: left;
    clear: left;
    width: auto;
    min-width: 120px;
  }
  a {
    @include govuk-font(16);
  }
  dd {
    display: table-cell;
  }
  p {
    margin: 0;
  }
}
