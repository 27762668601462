/* OVERRIDES

These styles can be used to override defaults, and should be used fairly sparingly.

Naming convention follows https://design-system.service.gov.uk/styles/spacing/

*/

/* Overrides for specific colours */
.eff-\!-background-light-blue {
  background-color: govuk-colour("light-blue");
}

.eff-\!-background-turquoise {
  background-color: govuk-colour("turquoise");
}

.eff-\!-grey-1 {
  color: govuk-colour("dark-grey");
}


.eff-\!-fill-width {
  width: 100%;
  width: -moz-available;
  width: -webkit-fill-available;
  width: fill-available;
}
