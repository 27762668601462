/*
  Back to Top

  Inspired by https://github.com/alphagov/govuk-design-system/blob/master/src/stylesheets/components/_back-to-top.scss
*/

.app-back-to-top {
  padding-bottom: govuk-spacing(4);
}

.app-back-to-top--fixed {
  position: fixed;
  bottom: 0px;
  left: 0;
  margin: 0;
  padding: 0;
  width: 100%;
  background-color: govuk-colour("light-grey");
  z-index: 1;
}

.app-back-to-top--fixed .app-back-to-top__container {
  max-width: 990px;
  margin: 0 auto;
}

.app-back-to-top--fixed .app-back-to-top__link {
  padding: 15px;
  display: block;
  outline: 0;
}

.app-back-to-top__icon {
  display: inline-block;
  width: .8em;
  height: 1em;
  margin-top: -(govuk-spacing(1));
  margin-right: govuk-spacing(2);
  vertical-align: middle;
}
