
.chart-and-footer {
  margin-bottom: 40px;
}

.rd_chart {
  font-family: "nta", Arial, sans-serif !important;

  tspan, p, span, text, g {
    font-family: "nta", Arial, sans-serif !important;
    font-size: 13px !important;
    text-align: center;

    @media(min-width: 768px) {
      font-size: 16px !important;
    }
  }

  .highcharts-title {
    font-size: 19px !important;
  }
}

.chart-container {
}

.highcharts-legend-item {
  tspan, p, span, text, g {
    font-size: 16px !important;
  }
}

.highcharts-axis {
  stroke-width: 0;

  path {
    stroke-width: 0;
  }
}

// shadow reduction

.highcharts-label.highcharts-tooltip {
  path[isShadow=true] {
    &:first-child {
      display: block;
    }

    display: none;
  }
}

// panel line chart

.panel-line-chart {
  .highcharts-container {
    position: relative;
    margin-top: 60px;
    overflow: visible !important;
  }

  .highcharts-title {
    display: block;
    width: 100% !important;
    left: 0 !important;
    font-size: 16px !important;
    font-weight: bold;
    top: -30px !important;
  }

  .highcharts-xaxis-labels {
    position: relative;

    span {
      font-size: 12px !important;
      color: #0B0C0C !important;
      min-width: 40px;
      margin-top: -15px !important;
      padding-top: 20px !important;

      &:first-child {
        text-align: left;
        margin-left: 20px !important;
      }

      &:last-child {
        margin-left: -10px !important;
        text-align: right;
      }
    }
  }

  .highcharts-axis.highcharts-xaxis {
    .highcharts-tick {
      display: none !important;
    }

    .highcharts-axis-line {
      display: none !important;
    }
  }
}

.small-chart-title {
  padding: 0 15px;
}

.highcharts-title {
  tspan {
    font-weight: bold;
  }
}

.highcharts-xaxis-labels {
  text, span, tspan {
    font-size: 16px !important;
  }
}

.highcharts-xaxis .highcharts-axis-line {
  stroke: black;
}

.highcharts-label-box.highcharts-tooltip-box {
  stroke: #6F777B;
}

.highcharts-tooltip {
  span, text {
    line-height: 120%;

    span, tspan {
      &.first {
        font-size: 12px !important;
      }
    }
  }
}

.chart-download {
  text-align: right;
  margin-bottom: 1em;
  font-size: 16px;

  a {
    margin-left: 15px;
  }
}
