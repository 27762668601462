/* Document footer
 *
 * This can be used to add a common footer to documents, containing metadata
 * such as the publication date, previous editions, and a version history.
 *
 * Adapted from GOV.UK pages.
 *
 * TODO: convert all classes to use the naming convention;
*/
.eff-document-footer {
  @include govuk-font(16);
  line-height: 1.25;
  margin-top: 20px;
  margin-left: -15px;
  margin-right: -15px;
}

.eff-document-footer:after {
  content: "";
  display: block;
  clear: both
}

.eff-document-footer .history-information {
  float: left;
  width: 33.33333%;
  padding: 0 15px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}

.eff-document-footer .related-information {
  float: left;
  width: 66.66667%;
  padding: 0 15px;
  -webkit-box-sizing: border-box;
  -moz-box-sizing: border-box;
  box-sizing: border-box;
}


.eff-document-footer .definition {
  @include govuk-font(24);
  line-height: 1.25;
  font-family: "nta", Arial, sans-serif;
  font-weight: 700;
  text-transform: none;
  margin-top: 5px;
  display: block
}

.previous-editions {
  margin-top: 20px;
}

.previous-editions ol {
  list-style-type: none;
  margin-top: 10px;
}

.previous-editions li {
  @include govuk-font(24);
  line-height: 1.25;
  font-weight: bold;
  margin-bottom: 10px;
}

.previous-editions a {
  text-decoration: none;
}

.previous-editions a:hover {
  color: #2b8cc4;
}
