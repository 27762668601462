/* DASHBOARDS */
$dashboard-background-highlight: #005EA5;
$dashboard-background-light: #F5F5F5;
$dashboard-background-bold: #BFC1C3;


.progress-card {
  // FUNCTIONALITY
  height: 80px;
  background-color: $dashboard-background-light;
  text-align: center;
  padding: 10px;
  margin-bottom: 20px;
  border-radius: 3px;

  &--selected {
    color: white;
    background-color: $dashboard-background-highlight;
  }

  &--active {
    &:hover {
      background-color: $dashboard-background-bold;
      color: white;
      cursor: pointer;
    }
  }

  &__value {
    font-size: 5rem;
  }

}
