
.gem-c-related-navigation {
    color: #0b0c0c;
    border-top: 2px solid #1d70b8
}

@media print {
    .gem-c-related-navigation {
        color: #000000
    }
}

.gem-c-related-navigation__main-heading {
    font-family: "nta", Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.25;
    margin-top: 15px;
    margin-bottom: 10px
}

@media print {
    .gem-c-related-navigation__main-heading {
        font-family: sans-serif
    }
}

@media (min-width: 40.0625em) {
    .gem-c-related-navigation__main-heading {
        font-size:19px;
        line-height: 1.3157894737
    }
}

@media print {
    .gem-c-related-navigation__main-heading {
        font-size: 14pt;
        line-height: 1.15
    }
}

.gem-c-related-navigation__sub-heading {
    font-family: "nta", Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.1428571429;
    border-top: 1px solid #b1b4b6;
    margin: 0;
    padding-top: 15px
}

@media print {
    .gem-c-related-navigation__sub-heading {
        font-family: sans-serif
    }
}

@media (min-width: 40.0625em) {
    .gem-c-related-navigation__sub-heading {
        font-size:16px;
        line-height: 1.25
    }
}

@media print {
    .gem-c-related-navigation__sub-heading {
        font-size: 14pt;
        line-height: 1.2
    }
}

.gem-c-related-navigation__sub-heading--footer {
    font-family: "nta", Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.25;
    border-top: 0;
    padding-top: 0;
    margin-top: 15px;
    margin-bottom: 10px
}

@media print {
    .gem-c-related-navigation__sub-heading--footer {
        font-family: sans-serif
    }
}

@media (min-width: 40.0625em) {
    .gem-c-related-navigation__sub-heading--footer {
        font-size:19px;
        line-height: 1.3157894737
    }
}

@media print {
    .gem-c-related-navigation__sub-heading--footer {
        font-size: 14pt;
        line-height: 1.15
    }
}

.gem-c-related-navigation__main-heading+.gem-c-related-navigation__sub-heading {
    border-top: 0;
    padding-top: 0
}

.gem-c-related-navigation__sub-heading--other {
    font-family: "nta", Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 700;
    font-size: 16px;
    line-height: 1.25;
    border-top: 0;
    padding-top: 0
}

@media print {
    .gem-c-related-navigation__sub-heading--other {
        font-family: sans-serif
    }
}

@media (min-width: 40.0625em) {
    .gem-c-related-navigation__sub-heading--other {
        font-size:19px;
        line-height: 1.3157894737
    }
}

@media print {
    .gem-c-related-navigation__sub-heading--other {
        font-size: 14pt;
        line-height: 1.15
    }
}

.gem-c-related-navigation__nav-section {
    margin-bottom: 30px
}

.gem-c-related-navigation__link {
    list-style-type: none;
    margin-top: 15px;
    font-family: "nta", Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-weight: 400;
    font-size: 14px;
    line-height: 1.45
}

@media print {
    .gem-c-related-navigation__link {
        font-family: sans-serif
    }
}

@media (min-width: 40.0625em) {
    .gem-c-related-navigation__link {
        font-size:16px;
        line-height: 1.45
    }
}

@media print {
    .gem-c-related-navigation__link {
        font-size: 14pt;
        line-height: 1.45
    }
}

@media (min-width: 40.0625em) {
    .gem-c-related-navigation__link {
        line-height:1.28
    }
}

.gem-c-related-navigation__link:focus,.gem-c-related-navigation__link:active:focus,.gem-c-related-navigation__link:link:focus,.gem-c-related-navigation__link:visited:focus {
    outline: 3px solid transparent;
    color: #0b0c0c;
    background-color: #ffdd00;
    box-shadow: 0 -2px #ffdd00, 0 4px #0b0c0c;
    text-decoration: none;
    color: #0b0c0c !important
}

.gem-c-related-navigation__link--truncated-links {
    margin-top: 10px
}

.gem-c-related-navigation__toggle {
    font-family: "nta", Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    display: none
}

@media print {
    .gem-c-related-navigation__toggle {
        font-family: sans-serif
    }
}

.gem-c-related-navigation__toggle:focus {
    outline: 3px solid transparent;
    color: #0b0c0c;
    background-color: #ffdd00;
    box-shadow: 0 -2px #ffdd00, 0 4px #0b0c0c;
    text-decoration: none
}

.gem-c-related-navigation__toggle:link {
    color: #1d70b8
}

.gem-c-related-navigation__toggle:visited {
    color: #1d70b8
}

.gem-c-related-navigation__toggle:hover {
    color: #003078
}

.gem-c-related-navigation__toggle:active {
    color: #0b0c0c
}

.gem-c-related-navigation__toggle:focus {
    color: #0b0c0c
}

.js-enabled .gem-c-related-navigation__toggle {
    display: inline-block
}

.gem-c-related-navigation__section-link {
    font-weight: bold
}

.gem-c-related-navigation__section-link:focus,.gem-c-related-navigation__section-link:active:focus,.gem-c-related-navigation__section-link:link:focus,.gem-c-related-navigation__section-link:visited:focus {
    outline: 3px solid transparent;
    color: #0b0c0c;
    background-color: #ffdd00;
    box-shadow: 0 -2px #ffdd00, 0 4px #0b0c0c;
    text-decoration: none;
    color: #0b0c0c !important
}

.gem-c-related-navigation__section-link--other {
    font-weight: normal
}

.gem-c-related-navigation__section-link--other:focus,.gem-c-related-navigation__section-link--other:active:focus,.gem-c-related-navigation__section-link--other:link:focus,.gem-c-related-navigation__section-link--other:visited:focus {
    outline: 3px solid transparent;
    color: #0b0c0c;
    background-color: #ffdd00;
    box-shadow: 0 -2px #ffdd00, 0 4px #0b0c0c;
    text-decoration: none;
    color: #0b0c0c !important
}

.gem-c-related-navigation__section-link--footer:focus,.gem-c-related-navigation__section-link--footer:active:focus,.gem-c-related-navigation__section-link--footer:link:focus,.gem-c-related-navigation__section-link--footer:visited:focus {
    outline: 3px solid transparent;
    color: #0b0c0c;
    background-color: #ffdd00;
    box-shadow: 0 -2px #ffdd00, 0 4px #0b0c0c;
    text-decoration: none;
    color: #0b0c0c !important
}

.gem-c-related-navigation__section-link--inline {
    line-height: 1.45
}

.gem-c-related-navigation__link-list {
    padding: 0;
    margin: 0;
    list-style: none;
    margin-bottom: 1.25em
}

@media (min-width: 40.0625em) {
    .gem-c-related-navigation__toggle-more .gem-c-related-navigation__section-link {
        line-height:1.45
    }
}

.js-enabled .gem-c-related-navigation__toggle-more.js-hidden {
    display: none
}
