/* Lifted (with minor modifications) from:
https://github.com/alphagov/static/blob/99f41342b66cde279f231fdc0834fac493260158/app/assets/stylesheets/helpers/_header.scss */

.eff-search__label {
  font-family: "GDS Transport",Arial,sans-serif;
  float: left;
  color:	#626a6e;
  font-weight: 300;
  min-height: 36px;
  line-height: 35px;
  text-indent: 15px;
  overflow: hidden;
  display: block;
  margin-right: 10px;

  .js-enabled & {
    float: left;
    position: absolute;
    left: 0;
    top: 1px;
    z-index: 1;
    width: 100%;
    margin-right: 0;
  }
}

.eff-search__form {
  position: relative;
  background: #fff;
  overflow: hidden;
  margin-bottom: 13px; /* This is to match the spacing above. */

  @media (max-width: 769px) {
    @include govuk-responsive-margin(3, 'bottom')
  }

  @media screen and (max-width: 379px) {
    display: none;
    width: 100%;
  }

  &.js-visible { display: block; }
}

.eff-search__toggle {
  display: none;

  @media screen and (max-width: 379px) {
    display: block;
    float: right;
    height: 36px;
    width: 36px;
    padding: 0;
    margin: -38px 0px;
    overflow: hidden;
    text-indent: -5000px;
    background-color: govuk-colour("blue");
    background-image: url('/static/images/icon-search-govuk.png');
    background-repeat: no-repeat;
    background-position: 0 50%;

    body.search & { display: none; }

    @include govuk-device-pixel-ratio() {
      background-size: 52.5px 35px;
      background-position: 100% 50%;
    }
  }

  &:hover {
    background-color: darken($govuk-brand-colour, 5%);
  }

  &.js-hidden { display: none; }
}

.eff-search__input {
  box-sizing: border-box;
  float: left;
  min-width: 0;
  display: block;
  margin: 0;
  border: 0;
  min-height: 36px;
  padding: 6px 0;
  &::-moz-focus-inner {
    border: 0;
  }

  -webkit-appearance: none;
}

.eff-search__input--text {
  @include govuk-font(16);
  position: relative;
  padding: 6px;
  z-index: 3;
  background: transparent;

  &.focus,
  &:focus {
    background: #fff;
    outline: 3px solid #fd0;
    outline-offset: -3px;
  }

  .js-enabled & {
    width: calc(100% - 36px);  // Full width, minus width of `input.submit` button
  }

  //@media screen and (max-width: 379px) {
  //  width: 80%;
  //}
}

.eff-search__input--submit {
  position: absolute;
  z-index: 4;
  right: 0;
  top: 0;
  width: 36px;
  height: 36px;

  border: 1px solid $govuk-brand-colour;
  border-left-color: #222;
  border-radius: 0;

  overflow: hidden;
  text-indent: -5000px;

  color: govuk-colour("white");
  background-color: $govuk-brand-colour;
  background-image: url('/static/images/icon-search-govuk.png');
  background-repeat: no-repeat;
  background-position: 0 50%;

  @include govuk-device-pixel-ratio() {
    background-size: 52.5px 35px;
    background-position: 100% 50%;
  }

  &:hover {
    background-color: darken($govuk-brand-colour, 5%);
  }

  &.focus,
  &:focus {
      border-width: 0;
      box-shadow: inset 0 0 0 4px #ffdd00;
      outline: 3px solid #ffdd00;
      outline-offset: 0;
  }
}


.eff-search__input--submit_black {
  position: absolute;
  z-index: 4;
  right: 0;
  top: 0;
  width: 36px;
  height: 36px;

  border: 1px solid #222;
  border-left-color: #222;
  border-radius: 0;

  overflow: hidden;
  text-indent: -5000px;

  color: govuk-colour("white");
  background-color: #222;
  background-image: url('/static/images/icon-search-govuk.png');
  background-repeat: no-repeat;
  background-position: 0 50%;

  @include govuk-device-pixel-ratio() {
    background-size: 52.5px 35px;
    background-position: 100% 50%;
  }

  &:hover {
    background-color: darken(#222, 5%);
  }

  &.focus,
  &:focus {
    border-width: 0;
    box-shadow: inset 0 0 0 4px #ffdd00;
    outline: 3px solid #ffdd00;
    outline-offset: 0;
  }
}
