/* Social share buttons
 *
 * Use this for links to share pages on some common social media
 * platforms.
 *
 * Supports Twitter, Facebook and LinkedIn.
 *
 * TODO: convert to govuk-frontend CSS conventions.
 */
.share a {
  text-decoration: none;
  line-height: 32px;
}

.share a:before {
  content: '';
  width: 32px;
  height: 32px;
  margin-right: 6px;
  background-color: $govuk-link-colour;
  display: inline-block;
  vertical-align: middle;
  background-size: 28px 28px;
  background-position: center center;
  background-repeat: no-repeat;
  border-radius: 1.5px;
}

/* Visited colour should apply to text and icon */
.share a:visited {
  color: $govuk-link-visited-colour;
}

.share a:visited:before {
  background-color: $govuk-link-visited-colour;
}

/* Hover color should apply to text and icon, even when visited */
.share a:visited:hover {
  color: $govuk-link-hover-colour;
}

.share a:hover:before,
.share a:visited:hover:before {
  background-color: $govuk-link-hover-colour;
}

.share a.linkedin:before {
  background-image: url('/static/images/icon-linkedin.svg');
}

.share a.twitter:before {
  background-image: url('/static/images/icon-twitter.svg');
}

.share a.facebook:before {
  background-image: url('/static/images/icon-facebook.svg');
}
