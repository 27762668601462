/* Additional styles to support our use of GOV.UK Frontend lists
 */

.eff-list--hint {
  color: govuk-colour("dark-grey");
}

.eff-list--sparse > li {
  @include govuk-responsive-margin(4, "bottom");
}
